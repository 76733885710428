<template lang="pug">
  .rate-plan-contents
    .rate-plan-details(v-if="isPlanFormShown")
      Details(
        :plan-id="selectedPlanId"
        @back="hidePlanForm"
        @remove-plan="removePlan"
      )
    .rate-plan-table(v-else)
      AppOverlayLoader(:state="plansLoading")
      Filters(
        :filters="filters"
        :otas="companyOtas"
        :shops="shops"
        :car-classes="carClasses"
        @change-filters="changeFilters"
        @clear-filters="clearFilters"
      )
      ActionBar(
        @add-new="showPlanForm"
        :with-download="false"
      )
      Table(
        :items="plans"
        :otas="companyOtas"
        :sorting-data="sorting"
        @sorting="changeSorting"
        @select-row="selectPlanItem"
        @remove-row="removePlan"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // misc
  import { SERIALIZER_VIEW_SHORT } from "@/config/constants"
  import { appDebounce } from "@/helpers/common"
  import { mapGetters } from "vuex"
  import { filter, map } from "lodash-es"

  // store modules
  import otasModule from "@/config/store/company_otas"
  import carClassesModule from "@/config/store/company_system/car_class_settings/car_classes"
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import ratePlanModule from "@/config/store/company_system/rate_plan_settings/rate_plan/basic_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withSorting from "@/mixins/withSorting"
  import withConfirmation from "@/mixins/withConfirmation"

  const otasMixin = withStoreModule(otasModule, {
    name: "companyOtas",
    readers: { otas: "items" },
    actions: { fetchOtas: "FETCH_ITEMS" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "companiesCarClasses",
    readers: { carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const ratePlanMixin = withStoreModule(ratePlanModule, {
    name: "ratePlan",
    resetState: true,
    readers: {
      plans: "items",
      filters: "filters",
      plansLoading: "loading",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchPlans: "FETCH_ITEMS",
      deletePlan: "DELETE_ITEM"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      resetFilters: "RESET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      Details: () => import("./Details"),
      Filters: () => import("./Filters"),
      Table: () => import("./Table"),
      ActionBar: () => import("../../ActionBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [ratePlanMixin, otasMixin, shopsMixin, carClassesMixin, withSorting, withConfirmation],

    created() {
      this.debouncedFetchPlans = appDebounce(() => this.fetchPlans())
    },

    data() {
      return {
        isPlanFormShown: false,
        selectedPlanId: null
      }
    },

    mounted() {
      this.fetchOtas()
      this.fetchShops({ pagination: { _disabled: true } })
      this.fetchCarClasses({
        pagination: { _disabled: true },
        serializer_view: SERIALIZER_VIEW_SHORT
      })
      this.fetchPlans()
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      companyOtaIds() {
        return map(this.otas, "id")
      },

      companyOtas() {
        return filter(this.translatedOtaList, ({ id }) => this.companyOtaIds.includes(id))
      }
    },

    methods: {
      selectPlanItem(plan) {
        this.selectedPlanId = plan.id
        this.showPlanForm()
      },

      showPlanForm() {
        this.isPlanFormShown = true
      },

      hidePlanForm() {
        this.isPlanFormShown = false
        this.selectedPlanId = null
        this.fetchPlans()
        this.fetchOtas()
      },

      removePlan(plan) {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.delete_plan_confirmation"),
          resolve: {
            handler: () => {
              this.deletePlan(plan.id)
            }
          }
        })
      },

      clearFilters() {
        this.resetFilters()
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchPlans()
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchPlans()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchPlans()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchPlans()
      }
    }
  }
</script>

<style lang="sass" scoped></style>
